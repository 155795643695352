import { lazy } from "react";



const Routes = [
    {
        path: "/",
        component: lazy(() => import('../loginpages/Login.js')),
        name: '/'
    },
    {
        path: "/token",
        component: lazy(() => import('../token/tokenlist.js')),
        name: '/token'
    },
    {
        path: "/token/:token",
        component: lazy(() => import('../token/add_edit_token.js')),
        name: '/token'
    },
    {
        path: "/tokenadd",
        component: lazy(() => import('../token/add_edit_token.js')),
        name: '/token'
    },
    {
        path: "/login/admin",
        component: lazy(() => import('../loginpages/Login.js')),
    
    },
  {
        path: "/plans",
        component: lazy(() => import('../plans/planlist')),
        name: '/admin'
    },
    {
        path: "/editplan",
        component: lazy(() => import('../plans/planedit')),
        name: '/admin'
    },
    {
        path: "/users",
        component: lazy(() => import('../user/userlist')),
        name: '/admin'
    },
    {
        path: "/userdetail",
        component: lazy(() => import('../user/userdetail')),
        name: '/admin'
    },
    {
        path: "/faq",
        component: lazy(() => import('../faqlist/faqlist')),
        name: '/admin'
    },
    {
        path: "/editfaq",
        component: lazy(() => import('../faqlist/editfaq')),
        name: '/admin'
    },  {
        path: "/addfaq",
        component: lazy(() => import('../faqlist/addfaq')),
        name: '/admin'
    },
    {
        path: "/cmslist",
        component: lazy(() => import('../CMS/cmsbox')),
        name: '/admin'
    },
    {
        path: "/editcms/:data",
        component: lazy(() => import('../CMS/editcms')),
        name: '/admin'
    },
    {
        path: "/editroadmap",
        component: lazy(() => import('../roadmap/editroadmap')),
        name: '/admin'
    }, {
        path: "/roadmaplist",
        component: lazy(() => import('../roadmap/roadmaplist')),
        name: '/admin'
    }, {
        path: "/tabs",
        component: lazy(() => import('../Tabs/TabsList')),
        name: '/admin'
    },
    {
        path: "/edittabs",
        component: lazy(() => import('../Tabs/edittabs')),
        name: '/admin'
    },  {
        path: "/addtabs",
        component: lazy(() => import('../Tabs/addtabs')),
        name: '/admin'
    },{
        path: "/contactlist",
        component: lazy(() => import('../contactus/contactuslist.js')),
        name: '/admin'
    },
    {
        path: "/contactusdetails",
        component: lazy(() => import('../contactus/contactusdetails.js')),
        name: '/admin'
    }
]


export default Routes;

import React, { Component, useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {  Trans } from 'react-i18next';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';






import { Account_disConnect  } from "../../redux/action.js";


import config from '../../lib/config.js'

toast.configure();



export default function Navbar() {


  // functions for modal 

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };


  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);




  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }





  const location = useLocation();
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const Wallet_Details = useSelector((state) => state.wallet_detail)
  const history = useHistory();

  const [connectButton, setConnectButton] = React.useState(true);












  const Disconnect = async () => {

 
    localStorage.removeItem('walletConnectType');
    dispatch({
      type: Account_disConnect,
      Account_Detail_Disconnect: {
        SingleContract: null,
        MultipleContract: null,
      
        providers: null,
      
      
        Accounts: '',
        AddressUserDetails: null,
    
        load: "false"
      }
    })



  }

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('walletConnectType');
    dispatch({
      type: Account_disConnect,
      Account_Detail_Disconnect: {
        SingleContract: null,
        MultipleContract: null,
      
        providers: null,
  
     
        Accounts: '',
        AddressUserDetails: null,
    
        load: "false"
      }
    })
    localStorage.setItem("adminlogin", "no");
    history.push("/")
  }






  return (
    <>
      <nav className={(location?.pathname == "/")?"navbar loginnavbar p-0 fixed-top d-flex flex-row":"navbar p-0 fixed-top d-flex flex-row"}>
        {console.log("navbar")}
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/naiftytlogo.png')} alt="logo" /></Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button className={(location?.pathname == "/")?"d-none":"navbar-toggler align-self-center"} type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
            <span className="mdi mdi-menu"></span>
          </button>
         
          <ul className="navbar-nav navbar-nav-right">
            {(!Wallet_Details.UserAccountAddr) ?
              <div className="navbar-profile">
              </div> :


              <Dropdown alignRight as="li" className="nav-item">
             

                <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                  <h6 className="p-3 mb-0"><Trans>Profile</Trans></h6>
                  <Dropdown.Divider />
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-logout text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <button onClick={Disconnect} className="preview-subject mb-1 allbtnlogout"><Trans>Disconnect</Trans></button>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>
            }

            {(localStorage.adminlogin == "yes") &&
              <Dropdown alignRight as="li" className="nav-item">
                <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
                  <div className="navbar-profile">
                    <p className="mb-0 d-none d-sm-block navbar-profile-name"><Trans>LOG OUT</Trans></p>
                    <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                  <h6 className="p-3 mb-0"><Trans>Profile</Trans></h6>
                  <Dropdown.Divider />
                  <Dropdown.Divider />
                  <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-logout text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <button onClick={() => logout()} className="preview-subject mb-1 btn allbtnlogout"><Trans>Log out</Trans></button>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>}

          </ul>


          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>

      </nav>
      <>
     
        
        
        </>

    </>

  );

}


  
const Envname = "demo"

let key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.BLOCKS_PER_YEAR = 0
key.SECRET_KEY = "Su11@mArb!trag3"
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE = /^\d{10}$/
key.NumOnly = /^\d+$/
key.PASSWORD = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/ 
key.notSpecil = /^[a-zA-Z-9]+$/
key.OnlyAlphSpecial = /^[A-Za-z_@.#&+-]*$/
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g

if (Envname === "local") {
    
    key.COMMON_URL = 'http://localhost:7000/common'
    key.BACK_URL = 'http://localhost:7000/front'
    key.AdminAPI = 'http://localhost:7000/admin'
    key.IMG_URL = 'https://backend-phoenix.maticz.in'
    key.chain_Id_List = [97, 43113]
    key.DEADADDRESS = '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract = '0xEF747c5efE5e0eb67DCC06896341dfb9DF5f4F29'.toLowerCase()
    key.ERC721 = '0x01769d2C31143933cda2680605f4D379c417e793'.toLowerCase()
    key.ERC1155 = '0x708390Ff3f96F4F46761319E07c50f0DADC22E66'.toLowerCase()
    key.erc20Address = '0x7CAB80ce0E55F46378E493B584eE61aD68878f11'.toLowerCase()
    key.RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/"
    key.CHAIN_ID = 97
    key.chain_Id_List = [97, 43113]
    key.Block_URL = "https://testnet.bscscan.com"
    key.COIN_NAME = "BNB"
}

if (Envname === "demo") {
    
    key.COMMON_URL = 'https://backend-sullam.maticz.in/common'
    key.BACK_URL ='https://backend-sullam.maticz.in/front'
    key.AdminAPI = 'https://backend-sullam.maticz.in/admin'
    key.adminaddress = '0x89A43F5070A578E8CF25549fB325294Fa033b8BA'.toLowerCase()
    key.IMG_URL =  'https://backend-sullam.maticz.in'
    key.DefaultImg = '/assets/images/avatar/avatar-01.png'
    key.CoverImg = 'https://backend-phoenix.maticz.in/demobanner.jpg'
   
}

export default key